body[data-section="home"] {
  &:not(.what_weve_done, .what_we_do) {
    #mainHeader {
      &.showLogo {
        h1 {
          pointer-events: none;
        }
      }
    }
  }
}

#mainHeader {
  --capabilities_lineheight: 1.75em;

  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  // padding-left: 4.6em;
  top: 0;
  left: 0;
  transform: translateZ(1px);
  z-index: 100;
  pointer-events: none;

  &.showPlayReel {
    #reel_link {
      opacity: 1;
    }
  }

  #reel_link {
    font-weight: $bold;
    position: absolute;
    line-height: 2.75em;
    top: 1em;
    right: 1em;
    padding-right: calc(3.5em);
    pointer-events: all;
    opacity: 0;
    transition: opacity 0.5s 0.5s, color 0.5s 0s;
    color: $black;

    .what_we_do & {
      color: $white;
    }

    span,
    svg {
      pointer-events: none;
    }

    svg {
      stroke: $orange;
      stroke-width: 5;
      stroke-linecap: round;
      stroke-miterlimit: 0;
      fill: transparent;
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      right: 0;
      width: 2.75em;
      height: 2.75em;
      transform: scale(0.75);
    }
  }

  h1 {
    opacity: 0;
    transition: opacity 0.5s $ease_in_out, color 0.5s;
    color: var(--header_color);
    align-self: flex-start;
    position: relative;

    // @supports(mix-blend-mode: lighten) {
    //   &::after {
    //     content: "";
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     left: 0;
    //     top: 0;
    //     background: green;
    //     mix-blend-mode: screen;
    //     position: absolute;
    //   }
    // }
  }

  &.showLogo {
    h1 {
      opacity: 1;
      transition-delay: 0.5s, 0s;
      cursor: pointer;
      pointer-events: all;
    }
  }

  &.open {
    pointer-events: all;

    ul {
      opacity: 1;
      transform: translate3d(0, 0rem, 2px);
      transition: opacity 0.5s $ease_in_out, transform 0.5s $ease_in_out;
      transition-delay: 1s;

      li {
        ul {
          opacity: 1;
          height: auto;
          max-height: calc(var(--num_capabilities) * var(--capabilities_lineheight)); // set the variable of number of capabilities in base.njk with eleventy and multiply it by the line-height to do this all in css
          transition: opacity 1s 0.75s, max-height 0.5s $ease_in_out 1s;
          overflow: hidden;
          transform: translate3d(0, 0rem, 2px);
          margin: 0.15em 0 0.5em 0.65em;

          @media (min-width: 429px) {
            max-height: calc(var(--num_capabilities) * var(--capabilities_lineheight));
            // margin: 0;
          }
        }
      }
    }

    address {
      opacity: 1;
      transition: opacity 1s 1s;
    }

    .social {
      li {
        display: inline-block;
        margin-right: 0.75em;
        margin-top: 1.5em;

        a {
          * {
            pointer-events: none;
          }

          &::after {
            display: none;
          }
        }

        svg {
          width: 1.5em;
          height: 1.5em;
          fill: $white;
        }

        .twitter {
          svg {
            transform: scale(1.55);
            fill: none;
            stroke: $white;
            stroke-width: 1.1;
          }
        }
      }
    }

    .close_button {
      svg {
        // stroke: $white;
      }
    }

    .open_button {
      pointer-events: none;
    }
  }

  &.close {
    pointer-events: none;

    h1 {
      // opacity: 0;
      transition-duration: 0.35s;
      transition-delay: 0s;
      // color: $orange;
    }

    address {
      opacity: 0;
      transition-duration: 0.35s;
      transition-delay: 0s;
    }

    ul {
      opacity: 0;
      transition-delay: 0s;
      transform: translate3d(0, -2rem, 2px);
      transition-duration: 0.25s;

      li {
        ul {
          max-height: 0;
          opacity: 0;
          transition-duration: 0.25s;
          transition-delay: 0.25s;
        }
      }
    }

    .open_button {
      pointer-events: all;
    }

    .close_button {
      transition: opacity 0.5s $ease_in_out 0s, transform 0s 0s;
      transform: translateZ(2px) rotate(0deg);

      opacity: 0;
    }
  }

  &.showBurger {
    .open_button {
      opacity: 1;
    }
  }

  .open_button {
    width: 2.75em;
    height: 2.75em;
    margin: 0;
    display: block;
    padding: 0;
    border: 0;
    background: transparent;
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 0;
    transform: translateZ(1px);
    cursor: pointer;
    pointer-events: all;
    transition: opacity 0.5s $ease_in_out 0.5s;
    opacity: 0;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      stroke: $orange;
      stroke-width: max(4px, 0.15em);
      pointer-events: none;
      transform: scale(0.75);

      line {
        stroke-dasharray: 100%;
        stroke-dashoffset: 0%;
      }
    }

    .no-touch &:hover {
      svg line {
        stroke-dashoffset: -200%;
        transition: stroke-dashoffset 0.35s $ease_out;

        &:nth-child(2) {
          transition-delay: 0.05s;
        }

        &:nth-child(3) {
          transition-delay: 0.1s;
        }
      }
    }
  }

  .close_button {
    width: 2.75em;
    height: 2.75em;
    margin: 0;
    display: block;
    padding: 0;
    border: 0;
    background: transparent;
    position: absolute;
    top: 1em;
    left: 1em;
    transform: translateZ(2px) rotate(0deg);
    opacity: 0;
    z-index: 3;
    cursor: pointer;
    transition: opacity 0.5s $ease_in_out 0s;
    // transition-delay: 0s;

    &.show {
      opacity: 1;
      transform: translateZ(2px) rotate(90deg);
      transition: opacity 0.5s $ease_in_out, transform 0.5s $ease_in_out;
      pointer-events: all;

      .no-touch &:hover {
        transform: translateZ(2px) rotate(180deg);
      }
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      stroke: currentColor;
      stroke-width: 0.2em;
      pointer-events: none;
      transform: scale(0.75);
      color: var(--header_color);
    }
  }

  .modal & {
    .close_button {
      svg {
        // stroke: $black;
      }
    }

    .open_button {
      opacity: 0;
      pointer-events: none;
      transition: none;
      transition-delay: 0s;
    }
  }

  a {
    color: $white;
    display: inline-block;
  
    &.selected {
      pointer-events: none;
    }
  }

  address {
    opacity: 0;
  }

  nav {
    position: absolute;
    z-index: 2;
    transform: translateZ(1px);
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    --top: calc(#{linearSizeWidth(16, 50, 429, 1600)} + 1.3rem);
    top: var(--top);
    height: calc(100% - var(--top) * 2);

    left: calc(env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)} * 1.75 + #{linearSizeWidth(44, 50, 429, 1600)});
    // calc(env(safe-area-inset-left) + #{linearSizeWidth(16, 50, 429, 1600)});

    font-size: linearSizeWidth(22, 28, 320, 375);

    h1 {
      font-family: 'Meloblioriac-Regular', Helvetica, Arial, sans-serif;
      font-weight: 400;
      // color: $white;
      font-size: 0.8571428571em;
      margin: 0;
      line-height: 1em;
    }
  
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      opacity: 0;
      transform: translate3d(0, -2rem, 2px);
      // font-size: linearSizeWidth(22, 28, 429, 768);
      // font-size: linearSizeWidth(22, 28, 320, 375);

      li {
        font-size: 1em;
        line-height: 1em;

        a {          
          font-weight: $bold;
          line-height: 1.5em;
          position: relative;

          &.capabilities_link {
            pointer-events: none;
          }

          // &::before,
          &::after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            background: $white;
            top: 1.15em;
            height: 3px;
            transform-origin: 0 0;
            transform: scaleX(0);
            transition: transform 0.25s $ease_in;
          }

          // &::before {
          //   // content: "";
          //   transform-origin: 100% 0;
          //   transform: scaleX(1);
          //   opacity: 0;
          //   transition: opacity 0.25s $ease_in, transform 0.25s $ease_in;
          // }

          &.selected,
          .no-touch &:hover {
            &::after {
              transition: transform 0.25s $ease_out;
              transform: scaleX(1);
            }

            // &::before {
            //   opacity: 1;
            //   transform: scaleX(0);
            //   transition: opacity 0.2s $ease_in_out, transform 0.2s $ease_out;
            // }
          }
        }
    
        ul {
          // font-size: 1.5em;
          line-height: 1.25em;
          font-weight: $normal;
          font-size: 0.6428571429em;
          height: 0;
          max-height: 0;
          opacity: 0;

          li {
            font-size: 1em;

            a {
              font-size: 1em;
              line-height: var(--capabilities_lineheight);
              font-weight: $normal;
    
              &.selected {
                // text-decoration: underline;
              }

              &::after {
                top: 1.3em;
              }
            }
          }
        }
      }
    }

    address {
      font-style: normal;
      font-size: 0.5em;
      line-height: 1.35em;
  
      a {
        text-decoration: none;
      }
  
      .address {
        color: $white;
        display: block;
        line-height: 1.35em;
      }
    }
  }

  @media (min-width: 429px) {
    .open_button,
    .close_button {
      width: linearSizeWidth(44, 50, 429, 1600);
      height: linearSizeWidth(44, 50, 429, 1600);
      top: linearSizeWidth(16, 50, 429, 1600);
      // left: linearSizeWidth(16, 50, 429, 1600);
      left: calc(env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)});
    }

    #reel_link {
      top: linearSizeWidth(16, 50, 429, 1600);
      right: calc(env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)});
    }
  }

  @media (orientation: portrait) {
    nav {
      .mobile & {
        height: calc(100% - var(--top) * 5);
      }
    }
  }

  @media (max-height: 600px) and (orientation: landscape) {
    nav {
      font-size: linearSizeHeight(20, 28, 520, 600);
    }
  }

  @media (max-height: 428px) and (orientation: landscape) {
    // .mobile & {
      height: 100%;

      nav {
        width: auto;
        height: calc(100% - var(--top) * 1.2);
        font-size: linearSizeHeight(22, 30, 320, 414);
  
        &>ul {
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: center;
  
          &>li {
            ul {
              position: absolute;
              left: calc(100% + 1em);
              top: 50%;
              transform: translateY(-50%) !important;
              padding-left: 1em;
              border-left: solid 1px $white;
              height: auto;
              max-height: none;
  
              li {
                a {
                  white-space: nowrap;
                }
              }
            }

            &>a[href*="capabilities"] {
              &::before {
                display: block;
                content: "";
                border-bottom: solid 1px $white;
                position: absolute;
                width: calc(0.6428571429em * 0.75);
                top: 50%;
                left: calc(100% + 0.6428571429em * 0.25);
              }
            }
          }
        }

        address {
          position: absolute;
          white-space: nowrap;
          left: calc(var(--screen_width) - (2em + env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)} * 1.75 + #{linearSizeWidth(44, 50, 429, 1600)}));
          transform: translateX(-100%);
          text-align: right;
        }
      }
    // }
  }
}