#footer {
  background: $orange;
  color: $white;
  opacity: 0;
  transition: opacity 0.25s;

  &.show {
    opacity: 1;
  }

  .footer_inner {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 8em;
    width: 1295px;
    max-width: calc(100% - 10rem);
    position: relative;
    margin: 0 auto;
    padding: 4em 0;
    // grid-template-rows: 1fr;

    .info {
      grid-column: 1 / span 1;
      
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    nav {
      grid-column: 2 / span 1;
      // grid-row: 1 / span 1;
    }

    .info {
      font-size: linearSizeWidth(22, 28, 320, 375);

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      h1 {
        font-family: 'Meloblioriac-Regular', Helvetica, Arial, sans-serif;
        font-weight: 400;
        color: $white;
        font-size: 0.8571428571em;
        margin: 0;
        line-height: 1em;
      }
    }

    nav {
      font-size: linearSizeWidth(22, 28, 320, 375);
    
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          font-size: 1em;
          line-height: 1em;

          a {          
            font-weight: $bold;
            line-height: 1.4em;
            position: relative;
            color: $white;

            &::after {
              content: "";
              display: block;
              width: 100%;
              position: absolute;
              background: $white;
              top: 1.15em;
              height: 3px;
              transform-origin: 0 0;
              transform: scaleX(0);
              transition: transform 0.25s $ease_in;
            }

            &.selected,
            .no-touch &:hover {
              &::after {
                transition: transform 0.25s $ease_out;
                transform: scaleX(1);
              }
            }
          }
      
          ul {
            line-height: 1.25em;
            font-weight: $normal;
            font-size: 0.6428571429em;
            margin: 0.25em 0;

            li {
              font-size: 1em;

              a {
                font-size: 1em;
                line-height: 1.5em;
                font-weight: $normal;
                color: $white;

                &::after {
                  top: 1.3em;
                }
              }
            }
          }
        }
      }
    }

    address {
      font-style: normal;
      font-size: 0.5em;
      line-height: 1.35em;

      a {
        text-decoration: none;
        color: $white;
      }

      .address {
        color: $white;
        display: block;
        line-height: 1.35em;
      }
    }

    .social {
      li {
        display: inline-block;
        margin-right: 0.75em;
        margin-top: 1.5em;

        a {
          * {
            pointer-events: none;
          }

          &::after {
            display: none;
          }
        }

        svg {
          width: 1.5em;
          height: 1.5em;
          fill: $white;
        }

        .twitter {
          svg {
            transform: scale(1.55);
            fill: none;
            stroke: $white;
            stroke-width: 1.1;
          }
        }
      }
    }
  }

  @media (max-width: 414px) {
    .footer_inner {
      grid-template-columns: max-content 1fr max-content;
      width: calc(100% - 2em);
      max-width: calc(100% - 2em);
      column-gap: 0em;

      .info {
        grid-column: 1 / span 1;
        
        display: flex;
        flex-flow: column;
        justify-content: space-between;
      }
  
      nav {
        grid-column: 3 / span 1;
        // grid-row: 1 / span 1;
      }
  
      nav {
        font-size: 1.5em;
      }
  
      address {
        font-size: 0.4em;
      }
    }
  }
}