@import './fonts';
@import './functions';

/*
 * COLORS
 */
$white: #fff;
$black: #474747;
// $orange: #edb735;
$orange: #f5b331;
$dark_gray: #1f1f1f;
$light_gray: #c8c8c8;

$primary_color: $white;
$secondary_color: $black;

/*
 * ANIMATIONS
 */
$ease_out: cubic-bezier(.23,.82,.44,1);
$ease_out: cubic-bezier(.25,0,.1,1);
$ease_in: cubic-bezier(.8,0,1,.73);//cubic-bezier(.42,0,1,1);
$ease_in_out: cubic-bezier(.3,.01,.2,1); //cubic-bezier(.5,.01,.35,1);

/**
 * HEADER AND FOOTER MARGINS
 */
$left_mobile: 2.3rem;
$right_mobile: 2.3rem;
$top_mobile: 2.3rem;
$bottom_mobile: 2.3rem;

$left_desktop: 15rem;
$right_desktop: 6.4rem;
$top_desktop: 5rem;
$bottom_desktop: $top_desktop;
