/* autoprefixer grid: on */

@import './includes/variables';

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 10px;
}

body {
    -ms-content-zooming: none; /* Disables zooming */
    color: $black;
    font-family: $regular_font;
    font-weight: $normal;
    font-size: 16px;//min(#{linearSizeWidth(10, 14, 320, 428)}, #{linearSizeHeight(8, 14, 320, 428)});
    line-height: 1.35em;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    // background: $white;
    top: 0;
    left: 0;
    width: 100vw;
    overflow-x: hidden;
    overflow-anchor: none;
    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
    

    &.menuOpen {
        overflow: hidden; //: fixed;
    }

    &.pointer {
        cursor: pointer;
    }

    &.cssloaded {
        opacity: 1;
        background: $white;
        transition: opacity 0.75s ease-in-out 0.25s, background-color 0.75s ease-in-out 0.25s;

        .arrow {
            stroke-width: 3;
        }

        &[data-section~="home"] {
            background: #000;
            position: fixed;
            overflow: hidden;

            * {
                user-select: none;
            }
        }
        
        &[data-section~="capabilities"],
        &[data-section~="about"],
        &[data-section~="contact"] {
            background: $dark_gray;
    
            .arrow {
                color: $light_gray;
            }
        }

        &[data-section~="work"] {
            background: $white;
    
            .arrow {
                color: $black;
            }
        }

        &.menuOpen,
        &.wiping {
            background: $orange;
        }
    }
}

#shell {
    overflow-x: hidden;
}

@-ms-viewport {
    width: device-width;
}

/* Screen Reader only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}

a {
    color: $black;
    -webkit-user-drag: none;
    text-decoration: none;
    line-height: 1em;
}

a:focus,
a:active,
button:focus,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: 0;
    line-height: 1em;
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $regular_font;
    font-weight: $normal;
    font-size: 1em;
    line-height: 1em;
}

.size_wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;

    &.stroke {
        border: solid 1px $orange;

        &.loaded {
            border-color: $dark_gray;
            transition: border-color 0.5s;
        }
    }

    &.desktop {
        // for desktop images 1600 x 1000
        padding-bottom: 62.5%;
    }

    &.wide {
        // 16 x 9
        padding-bottom: 56.25%;
    }

    &.tall {
        // for portrait mobile images 428 x 926
        padding-bottom: 216.35514%;
    }

    &.custom {
        padding-bottom: var(--proportion);
    }

    &.square {
        padding-bottom: 100%;
    }

    img {
        transform: translateY(50%) translateZ(1px);
    }

    img,
    video,
    .videoplayer,
    .pause_cover {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .videoplayer,
    .pause_cover {
        height: 100%;
    }

    &::after {
        content: "";
        background: $orange;
        position: absolute;
        display: block;
        width: 120%;
        height: 100%;
        transform-origin: 0 100%;
        transform: translate3d(0, 0%, 2px) rotate(0deg);
    }

    &.loaded {
        &::after {
            transform: translate3d(0, -101%, 2px) rotate(-6deg);
            transition: transform 0.5s $ease_in_out;
        }

        img {
            transform: translateY(0%) translateZ(1px);// scale(1);
            transition: transform 0.5s $ease_in_out;
        }
    }
}

#canvas_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
#glCanvas {
    position: fixed;
    top: 0;
    left: 0;
}

#icon-link {
    stroke: currentColor;
    stroke-width: 6;
    fill: none;
}

#icon-arrow {
    stroke: currentColor;
    // stroke-width: 3;
    fill: none;
}

#icon-plus {
    stroke: currentColor;
    // stroke-width: 3;
    fill: none;
}

@import './widgets/header';
@import './widgets/footer';